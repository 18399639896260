@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/Poppins-Light.ttf") format("truetype");
  font-weight: light;
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  height: 100%;
  overflow-y: hidden;
  /*  overflow-x: hidden;*/

  background: #000;
}
body > svg {
  position: absolute;
  top: 0;
  z-index: -1;
}

body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

.logo {
  position: fixed;
  z-index: 100;
  top: 20px;
  left: 20px;
  height: 20%;
  max-height: 100px;
}

.intro-screen {
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: #e6b016;
  z-index: 10000;
  position: fixed;
  padding: 200px 50px 80px;
}

.intro-screen.straatverklaring {
  z-index: 9999;
}

.verklaring-container {
  line-height: 1;
}

.scroll {
  overflow-y: auto;
  height: 100%;
}

.intro-screen {
  font-size: 27px;
  /* display: flex;
  justify-content: flex-start;
  flex: 1; */
}

.intro-screen .inner {
  padding: 32px;
}

.intro-screen .verklaring {
  font-size: 18px;
  display: inline;
}
.intro-screen .verklaring.big {
  font-weight: bold;
  display: block;
  margin: 16px 0;
}
.verklaring--text + .verklaring--text::before {
  content: "\A";
  display: block;
  height: 16px;
}

.verklaring--variable {
  font-weight: bold;
}

.intro-screen--close {
  padding-top: 70px;
}
.intro-screen__back {
  position: absolute;
  top: 24px;
  left: 46px;
  font-size: 18px;
}
.close {
  position: absolute;
  top: 5px;
  right: 20px;
}

.intro-text {
  font-size: 27px;
  display: flex;
  margin-bottom: 16px;
}
.intro-screen-text {
  margin: auto;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.intro-screen-text::-webkit-scrollbar {
  display: none;
}

.intro-screen button {
  background: #e6b016;
  color: black;
  cursor: pointer;
  border: none;
  font-weight: bold;
  font-size: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 16px;
  text-decoration: none;
}

@media (max-width: 900px) {
  .intro-screen {
    padding: 50px;
  }

  /* .intro-screen-text {
    margin: 50px;
  } */
  .intro-text {
    font-size: 18px;
  }

  .intro-screen button {
    font-size: 30px;
    margin-top: 45px;
  }
}

.orientation-background {
  background: url("https://hetgrotestraatberaad.nl/static/media/background/small/background_01.png");
  background-size: cover;

  display: block;
  height: 100%;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 200;
}

.orientation-message {
  background: rgba(0, 0, 0, 0.5);
  bottom: 20px;

  font-size: 1rem;

  top: 20px;
  left: 20px;
  right: 20px;

  position: absolute;
  z-index: 1000;
}

.orientation-message img {
  display: block;
  margin: 24px auto;
  width: 250px;
}
.orientation-message span {
  color: #e6b016;
  display: block;
  font-size: 32px;

  margin: 16px;
  padding: 16px 0;
  text-align: center;
}

.v-center {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.menuToggle {
  display: inline-block;
  cursor: pointer;
  z-index: 9000;
  position: fixed;
  top: 32px;
  right: 32px;
}
.bar1,
.bar2,
.bar3 {
  width: 40px;
  height: 6px;
  background-color: white;
  margin: 10px 0;
  transition: 0.4s;
}

.showHamburger .bar1 {
  -webkit-transform: rotate(-45deg) translate(-16px, 0px);
  transform: rotate(-45deg) translate(-16px, 0px);
}

.showHamburger .bar2 {
  opacity: 0;
}

.showHamburger .bar3 {
  -webkit-transform: rotate(45deg) translate(-22px, -8px);
  transform: rotate(45deg) translate(-22px, -8px);
}

.menu {
  position: fixed;
  width: 400px;
  max-width: calc(100% - 16px);
  z-index: 199;
  top: 0;
  bottom: 0;
  padding-top: 32px;
  padding-left: 32px;
  color: #e6b016;
  right: -400px;
  transition: all 1s;
  font-size: 20px;
  background-color: black;
}

.menu a {
  color: #ffffff;
}

.showMenu {
  right: 0px;
  opacity: 1;
}

.boldWord {
  color: #e6b016;
  text-decoration: none;
  font-weight: bold;
}

.menuItem {
  color: #e6b016;
  text-decoration: none;
  margin-bottom: 16px;
  cursor: pointer;
  line-height: 1.2;
}
.menuItem a {
  color: #e6b016;
  text-decoration: none;
}

.colofon {
  color: #e6b016;
  margin-right: 4vw;
  font-size: 1em;
  /* padding: 40px;/ */
  overflow-y: auto;
  height: 100%;
}

.hidden {
  background: rgba(0, 0, 0, 0.2);

  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 150;
  left: 0;
  top: 0;
}

.blue {
  color: #04aaff;
}

.bold {
  font-weight: bold;
}
.big {
  font-weight: bold;
  font-size: 50px;
}
.white {
  color: white;
}

.questionLabel {
  background: rgba(0, 0, 0, 0.7);
  color: rgb(227, 173, 22);
  font-weight: bold;
  padding: 0 16px;
}

.voteButton {
  background: black;
  color: rgb(227, 173, 22);
  font-weight: bold;
  padding: 0 16px;
  cursor: pointer;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}
